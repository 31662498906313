/*
 * 1 - Add subtle dividing line between header and nav
 * 2 - Fall back to plain color if radial gradient rule not recognized by browser
 * 3 - Push logo to left side of header on larger viewports
 * 4 - Set logo SVG to 150-250px in width, resizing automatically, with white and
 *     Chordbomb's custom off-white as the stroke and fill colors, respectively
 */

#main-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid lighten($color: $cb-black, $amount: 10%); /* 1 */
  background-color: $cb-primary; /* 2 */
  background: radial-gradient(
    at top,
    lighten($color: $cb-primary, $amount: 8%),
    darken($color: $cb-primary, $amount: 18%)
  );

  @include respond-to('medium') {
    justify-content: flex-start; /* 3 */
  }
}

/* 4 */
#chordbomb-logo {
  min-width: 150px;
  max-width: 250px;
  width: auto;
  stroke: #fff;
  fill: $cb-white;
}
