/* Base button style */
button {
  cursor: pointer;
  border: none;
  background: none;
}

/* Chordbomb 'primary'-styled button */
button.primary {
  box-sizing: content-box;
  border-radius: 14px;
  color: $cb-white;
  background-color: $cb-primary;
  font-size: $h4;
  padding: 3px 10px;
  margin: 3px;

  &.large {
    font-size: $h3;
    padding: 4px 15px;
  }

  &:hover:not(:disabled) {
    color: #fff;
    background-color: lighten($color: $cb-primary, $amount: 12%);
    box-shadow: 0 0 2px 1px darken($color: $cb-white, $amount: 8%) inset;
  }

  &:disabled {
    cursor: default;
    color: $cb-gray;
    background-color: lighten($color: $cb-gray, $amount: 20%);
  }

  fa-icon {
    margin-right: 10px;
  }
}

/*
 * Refactor default 'primary' button style to make '+' and '-' node buttons (used in Song
 * Edit component): style as 20px-diameter circles with reset margins, padding, and no
 * right margin after icon as there is no text
 */
button#insert-node-btn, button#remove-node-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  margin: 0;

  & > fa-icon {
    margin: 0;
  }
}

/* Remove all margins to line up button with 'Edit Song' header text to the left */
button#create-new-song-btn {
  margin: 0;
}

button#submit-btn {
  margin: 20px;
}

/* Force plus and minus buttons to the right end of row; space them out slighly */
button#insert-node-btn {
  margin-right: 5px;
  margin-left: auto;
}

/* Admin login button */
button#login-btn {
  font-weight: bold;
  margin-top: 10px;

  /* Align right side of button with edge of form fields */
  width: 80px;
  margin-left: calc(100% - 80px);

  &:hover {
    background: lighten($color: $cb-white, $amount: 5%);
  }
}

/* Navigation bar burger menu button */
button#menu-btn {
  display: block;

  /*
   * Keep button stationary in top of navbar when dropdown is opened; pushed down
   * slightly to centre with top row a little better
   */
  margin-top: 5px;
  margin-bottom: auto;

  /* Button animation for when dropdown closes: transition 'X' -> three-line 'burger' */
  .burger-line {
    display: block;
    background-color: $cb-white;
    width: 25px;
    height: 2px;
    margin: 5px auto;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  /* Don't display burger menu button on medium viewports of larger */
  @include respond-to('medium') {
    display: none;
  }
}

/* Button animation for when dropdown opens: transition icon three-line 'burger' -> 'X' */
nav#app-nav.open button .burger-line {
  &:nth-child(1) {
    transform: translateY(8px) rotate(225deg);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  &:nth-child(2) {
    opacity: 0;
  }

  &:nth-child(3) {
    transform: translateY(-7px) rotate(-225deg);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
}
