/* Chordbomb custom color palette */
$cb-primary: #030636;
$cb-accent: #87bb67;

$cb-primary-palette: (
  Main: $cb-primary,
  Light: #804b85,
  Dark: #28002f,
  contrast: (
    Main: #eee,
    Light: #000,
    Dark: #fff
  )
);

$cb-accent-palette: (
  Main: $cb-accent,
  Light: #ffc453,
  Dark: #be6500,
  contrast: (
    Main: #fff,
    Light: #fff,
    Dark: #fff
  )
);

$cb-success: #22a31e;
$cb-select: #adbcff;
$cb-warn: #f00;
$cb-background: #f4efef;
$cb-fieldset: #d7d8dc;
$cb-white: #f0f0f0;
$cb-gray: #9e9e9e;
$cb-black: #0e0e0e;

/* Global font sizes */
$h1: 30px;
$h2: 24px;
$h3: 18px;
$h4: 14px;
$h5: 12px;
$h6: 11px;

/* Viewport breakpoint map */
$breakpoints: (
  'small': (min-width: 400px),
  'less-than-medium': (max-width: 699px),
  'medium': (min-width: 700px),
  'large': (min-width: 1000px),
  'x-large': (min-width: 1200px)
);

/* Miscellaneous default global variables */
$border-radius: 4px;
$component-spacing: 20px;
