#dashboard-container {
  height: 100%;
  background: $cb-background;
  padding: $component-spacing;
}

#sidenav-content {
  font-size: $h4;
  padding: 0 3em;

  legend.setting {
    display: block;
    margin: 1.6em 0 0.5em;
    font-weight: bold;
  }

  mat-radio-button.option {
    display: table;
    padding: 0.2em 0;

    &:hover {
      color: $cb-primary;
      font-weight: bold;
    }

    /* Hidden bold text ensures there's no layout shift when bold applied on hover */
    &::after {
      content: attr(data-text);
      content: attr(data-text) / '';
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: bold;

      @media speech {
        display: none;
      }
    }
  }
}
