/*
 * App laid out as a CSS grid, with three full-width rows: header, nav, main
 * 1 - Adjust header and nav heights to fit their contents, and give main the remainder
 * 2 - Allow main section to overflow, resulting in a fixed 'sticky' header
 * 3 - Give main section its own grid, consisting of router-outlet and app-footer
 * 4 - Split section into three columns: variable middle column with a limit on the width,
 *     and the remainder viewport width split equally on either side
 * 5 - Row 1 (router-outlet) will receive whatever is left over after row 2 (app-footer)
 *     has sized to fit its content (somehow only works with the 1fr-auto order reversed?)
 */

#app-container {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr; /* 1 */
  grid-template-areas:
    'header-area'
    'nav-area'
    'main-area';
}

app-header {
  grid-area: 'header-area';
}

app-nav {
  grid-area: 'nav-area';
}

#main-container {
  grid-area: 'main-area';
  overflow: auto; /* 2 */
  background-color: $cb-background;

  display: grid; /* 3 */
  grid-template-columns: 1fr; /* 4 */
  grid-template-rows: auto 1fr; /* 5 */
}
