$row-height: 1.5em;

app-song-display {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: $component-spacing;
}

mat-card#song-card {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  max-width: 800px;
}

mat-card-title#artist-and-title {
  width: 100%;
  color: $cb-primary;
}

mat-card-subtitle#song-details {
  width: 100%;

  .flex-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;

    .left-col img#album-cover {
      box-shadow: 1px 2px 3px 1px darken($color: $cb-white, $amount: 20%);
      width: 70px;
      height: 70px;
    }
    @include respond-to('medium') {
      .left-col img#album-cover {
        width: 120px;
        height: 120px;
      }
    }

    .right-col {
      text-align: left;
      em {
        margin-left: 0.5em;
        font-style: normal;
        font-weight: bold;
      }
    }
  }
}

mat-card-content#lyrics-and-chords {
  padding: 0;
  width: 100%;
  text-align: left;
  font-size: $h4;
  &.enlarge { font-size: $h3; }
  &.reduce { font-size: $h6; }
}

/* Use hr tags for various line breaks and spacings between song sections */
hr {
  &.new-line {
    height: 0;
  }

  &.empty-space {
    &.below {
      height: $row-height;
    }
    &.above {
      height: $row-height * 2;
    }
  }

  &.line-break {
    height: 3px;
    border: 1px solid $cb-white;
  }
}

.label {
  height: $row-height;
  font-weight: bold;
  color: $cb-primary;
}

/* Container for each node's lyric & chord to ensure consistent formatting */
.music-unit {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: left;
  vertical-align: top;
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 5s; /* Firefox < 16 */
      -ms-animation: fadein 5s; /* Internet Explorer */
       -o-animation: fadein 5s; /* Opera < 12.1 */
          animation: fadein 5s;

  .lyric {
    height: $row-height;
  }

  .chord {
    height: 1.5 * $row-height;
    line-height: 1.3em; // Better aligns chords with flat symbols with those without
    padding-right: 0.8em;
    color: $cb-accent;
  }
}

/*
 * 'fadein' animation for .music-unit which encompasses all lyrics and chords
 * 1 - Firefox < 16
 * 2 - Safari, Chrome and Opera > 12.1
 * 3 - Internet Explorer
 * 4 - Opera < 12.1
 */
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-moz-keyframes fadein { /* 1 */
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-webkit-keyframes fadein { /* 2 */
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Hide excessively descriptive lines of text when on mobile (i.e. viewport < medium) */
.mobile-hide {
  display: none;
}
@include respond-to('medium') {
  .mobile-hide {
    display: block;
  }
}
