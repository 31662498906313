/*
 * Reduce default radius of the animated ripple around radio buttons:
 * 1 - height & width set at 2x circles radius
 * 2 - left and top set to (50% - circle radius) to centre ripple around button
 * 3 - circular boundary added to prevent clipping
 */
mat-radio-group.mat-radio-group .mat-radio-button .mat-radio-ripple {
  height: 30px; /* 1 */
  width: 30px;
  left: calc(50% - 15px); /* 2 */
  top: calc(50% - 15px);
  border-radius: 50%; /* 3 */
}

/* Replace default black outline on hover and when active with a subtler gray */
mat-form-field:hover .mat-form-field-outline,
.mat-focused .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: lighten($color: $cb-gray, $amount: 10%);
}

/* (Hacky) way of forcing mat-error box up into where the hint box would normally be */
mat-error {
  margin-top: -15px;
}
