/* Default global font family, font sizes and text colors */
* {
  font-family: 'Lato', sans-serif;
}

h1, .h1 { font-size: $h1; }
h2, .h2 { font-size: $h2; }
h3, .h3 { font-size: $h3; }
h4, .h4 { font-size: $h4; }
h5, .h5 { font-size: $h5; }
h6, .h6 { font-size: $h6; }

.success {
  color: $cb-success;
}

.warn {
  color: $cb-warn;
}