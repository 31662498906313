$label-margin-left: 0.3em; // Space between left edge of fieldset and label
$form-field-spacing: 0.2em; // Space between form fields
$fieldset-padding: 0.1em; // Padding along inner edges of fieldset
$form-row-padding: 0.5em; // Padding around form row (left & right fixed at all viewports)

#song-edit-header {
  display: flex;
  gap: 10px;
  justify-content: center;

  /* On larger viewports, move header and button to far edges of component container */
  @include respond-to('medium') {
    justify-content: space-between;
  }
}

#latest-ed-details {
  text-align: left;
  margin: 1em auto;
  padding: 0.6em 1.5em;
  width: calc(100% -
    2*(#{$form-field-spacing} + #{$fieldset-padding} + #{$form-row-padding})
  );
  box-shadow: 1px 2px 3px 1px lighten($color: $cb-gray, $amount: 5%);

  #latest-ed-note {
    font-size: $h4;
    margin-top: 0.5em;
    font-style: oblique;
  }
}

fieldset {
  background-color: $cb-fieldset;
  padding: $fieldset-padding;
  border: 1px solid lighten($color: $cb-black, $amount: 40%);
  border-radius: $border-radius;
  margin: 2em 0 0;

  legend {
    color: $cb-white;
    background-color: $cb-primary;
    font-size: $h3;
    font-weight: bold;
    margin-left: 0.5em;
    padding: 0.1em 0.35em;
    text-align: center;
    box-shadow: 0 0 3px 1px $cb-gray;
    width: inherit;
  }
}

.form-row {
  display: flex;
  padding: $form-row-padding;
  align-items: center;
  justify-content: flex-start;

  /* Reduce top-bottom padding on small viewports to better suit smaller font size */
  @include respond-to('medium') {
    padding: calc(#{$form-row-padding} / 2) $form-row-padding;
  }

  /* A single form-field in the row: force input under label, and no flex needed */
  &.form-field {
    display: block;
  }

  &.with-textarea {
    align-items: flex-start;
  }
}

.form-field, .node-number {
  /* Leave small gap between form fields, node number text, and edges of fieldset box */
  margin: 0 $form-field-spacing;

  &.full-width {
    width: calc(100% - #{$form-field-spacing}*2);
  }
  &.three-quarters-width {
    width: 75%;
  }
  &.half-width {
    width: 50%;
  }
  &.quarter-width {
    width: 25%;
  }
  &.node-field-width {
    width: 15%;
  }
}

.error-container {
  /*
   * Fixed height to ensure two side-by-side fields remain vertically aligned if one shows
   * an error but the other doesn't (a little hacky... but works)
   */
  height: 10px;

  .error {
    display: block;
    margin-top: 0.1em;
    padding: 0;
    margin-left: $label-margin-left;
    color: $cb-warn;
    font-size: $h6;
  }
}

.field-name, .node-number {
  font-size: $h4;
  margin-left: $label-margin-left;
}

/*
 * Viewport very narrow, so remove individual node number labels and have a single
 * label above all the nodes - increase left margin to align with input box (estimate);
 * also add a top margin to separate text from above fields; increase label's box width to
 * accomodate for full text when viewport is larger - a little hacky, but 5.5em should
 * comfortably allow for up to 3-digit numbers
 */
.node-number {
  font-weight: bold;

  &.short-text {
    margin-top: 20px;
    margin-left: ($label-margin-left + 0.7em);
  }

  &.full-text {
    width: 5.5em;
  }
}

/* Centre all items with some spacing in between */
#submit-song-container {
  margin: 0.5em;
  text-align: center;
}

/* Show abbreviated text when viewport is small; switch to full text when larger */
.full-text {
  display: none;
}
.short-text {
  &:not(.node-number) {
    display: inline;
  }
  &.node-number {
    display: block;
  }
}

@include respond-to('medium') {
  .full-text {
    display: inline;
  }
  .short-text {
    display: none !important;
  }
}
