#song-list-header {
  text-align: center;
  padding-bottom: 0.5em;
}

table#database-songs {
  border: 1px solid transparent;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 0 lighten($color: $cb-gray, $amount: 15%);
  overflow: hidden;
  display: inline-block;
}

thead th {
  font-size: $h4;
  padding: 0.4em 1em;
  box-shadow: 0 0 1px 0 lighten($color: $cb-gray, $amount: 15%);
  text-align: center;
  background-color: $cb-primary;
  color: $cb-white;
}

tbody tr {
  font-size: $h5;
  background-color: $cb-white;

  &:nth-child(even) {
    background-color: lighten($color: $cb-white, $amount: 10%);
  }

  &:hover {
    background-color: lighten($color: $cb-select, $amount: 10%);
    cursor: pointer;
  }
}

td {
  padding: 0.5em 0.8em;
  box-shadow: 0 0 1px 0 lighten($color: $cb-gray, $amount: 15%);

  &.align-right {
    text-align: right;
  }
}
