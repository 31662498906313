/*
 * 1 - Flex wrap the three items (lines of text) in vertical direction
 * 2 - Slightly increase letter spacing from the default
 * 3 - Add extra padding to turn icon closer to a perfect circle so that the 50% border
 *     radius styling creates the desired effect
 * 4 - On hover, text brightens to full white, letter spacing increases, and glow effect
       added behind text and icon
 */

footer {
  display: flex;
  flex-flow: column wrap; /* 1 */
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5em;
  line-height: 1.7em;
  font-size: $h5;
  color: $cb-white;
  background: $cb-black;
}

address#github-repo {
  text-transform: uppercase;
  letter-spacing: 0.06em; /* 3 */

  fa-icon {
    margin-left: 0.2em;
    padding: 0 2px; /* 4 */
    border-radius: 50%;
  }

  /* 5 */
  &:hover {
    color: #fff;
    letter-spacing: 0.12em;
    text-shadow: 0 0 1px $cb-white;

    fa-icon {
      box-shadow: 0 0 3px 1px $cb-white;
    }
  }
}
