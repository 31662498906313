*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, hr,
fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas,
embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  color: inherit;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

a {
  text-decoration: none;
  font-style: normal;
}

ol, ul {
  list-style: none;
  height: inherit;
}

blockquote, q {
  quotes: none;
  &:before, &:after {
    content: '';
    content: none;
  }
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}
